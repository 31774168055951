
import {defineComponent} from "vue";
import {IonButton, IonContent, IonItem, IonPage, IonText} from '@ionic/vue';
import {loadOrCreateUserId} from "@/util/Utils";


export default defineComponent({
  name: "HomeScreen",
  components: {IonButton, IonPage, IonText, IonItem, IonContent},
  setup() {
    loadOrCreateUserId();
  }
})
